import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { type MarginProps } from 'styled-system'
import throttle from 'lodash/throttle'
import { Absolute, Box, Relative } from 'pcln-design-system'
import { Gpt, GptProcessor } from '@pcln/ad-unit'

interface HeroImageParallaxProps {
  children: JSX.Element
  gptId: string
  slot: string
  isScrollable?: boolean
}

interface BackgroundWrapperProps {
  isScrollable: boolean
  height: number
}

const BackgroundWrapper = styled(Box)<BackgroundWrapperProps>`
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  position: ${props => (props.isScrollable ? 'absolute' : 'fixed')};
  left: 0;
  top: 0;
  z-index: -1;

  & iframe {
    height: ${props => props.height}px;
  }
`

const StyledAbsolute = styled(Absolute)`
  height: 100%;
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

function HeroImageParallax({
  children,
  gptId,
  slot,
  isScrollable = false,
  ...props
}: HeroImageParallaxProps & MarginProps) {
  const [height, setHeight] = useState(0)
  const currentContaineNodeRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const calculateHeight = () => {
      const headerHeight = document.getElementById('exp-hdr')?.offsetHeight || 0
      const heroContainerHeight =
        currentContaineNodeRef?.current?.offsetHeight || 0
      return headerHeight + heroContainerHeight
    }

    const setHeightThrottled = throttle(() => {
      setHeight(calculateHeight())
    }, 100)

    if (currentContaineNodeRef && currentContaineNodeRef.current) {
      window.addEventListener('resize', setHeightThrottled)
      const heroContainer = currentContaineNodeRef.current
      const config = { attributes: true, childList: true, subtree: true }
      const observer = new MutationObserver(setHeightThrottled)
      observer.observe(heroContainer, config)
      return () => {
        window.removeEventListener('resize', setHeightThrottled)
        observer.disconnect()
      }
    }
  }, [])

  return (
    <Relative ref={currentContaineNodeRef} {...props}>
      <Relative>{children}</Relative>
      <StyledAbsolute color="primary.dark" />
      <BackgroundWrapper
        width={1}
        height={height}
        isScrollable={isScrollable}
        className="HeroImageParallax"
        data-testid="hero-image-parallax"
      >
        <Gpt
          render={() => (
            <GptProcessor slot={slot} gptId={gptId} sizeMap={['fluid']} />
          )}
        />
      </BackgroundWrapper>
    </Relative>
  )
}

export default HeroImageParallax
