import { useEffect } from 'react'
import analytics from '@/shared-utils/analytics'
import { type IGoogleAnalyticsTrackingType } from '@pcln/contentful-components'

export default function useGoogleAnalyticsTracking(
  googleAnalyticsTracking?: IGoogleAnalyticsTrackingType
) {
  useEffect(() => {
    if (googleAnalyticsTracking?.parameters) {
      analytics.fireGA4Event(googleAnalyticsTracking.parameters)
    }
  }, [googleAnalyticsTracking])
}
