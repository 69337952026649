import React from 'react'
import { Link } from 'pcln-design-system'
import {
  ContentfulRichText,
  type IMerchModule
} from '@pcln/contentful-components'
import { MerchModule } from '@pcln/marketing'
import analytics from '@/shared-utils/analytics'
import useBootstrapData from '@/hooks/useBootstrapData'
import { isEmpty } from 'lodash'
import useGoogleAnalyticsTracking from './useGoogleAnalyticsTracking'

function MerchModuleWrapper({ content }: { content: IMerchModule }) {
  const { isMobile } = useBootstrapData()
  const {
    columnDisplayOptions,
    coupon,
    overline,
    heading,
    mediaDisplay,
    mediaPosition,
    mediaDisplayRatio,
    backgroundColor,
    textAlign,
    media,
    primaryCallToAction,
    paragraph,
    backgroundDisplay,
    hideMediaOnMobile,
    analyticsTracking,
    contentTypeId,
    ...props
  } = content
  useGoogleAnalyticsTracking(analyticsTracking)
  if (contentTypeId !== 'merchModule') {
    return null
  }
  const moduleButtons = primaryCallToAction && (
    <Link
      key={primaryCallToAction.ariaLabel}
      variation="fill"
      href={primaryCallToAction.url}
      target={primaryCallToAction.openInNewTab ? '_blank' : undefined}
      rel={primaryCallToAction.openInNewTab ? 'noopener' : undefined}
      aria-label={primaryCallToAction.ariaLabel}
      onClick={() => {
        const ga4Parameters = primaryCallToAction?.analyticsTracking?.parameters
        if (ga4Parameters) {
          analytics.fireGA4Event(ga4Parameters)
        }
      }}
    >
      {primaryCallToAction.label}
    </Link>
  )
  const isBoxCheck = backgroundDisplay === 'box'
  const imageDisplayMobile = hideMediaOnMobile === false
  const FALLBACK_ALT_TEXT = 'banner image'
  return (
    <MerchModule
      coupon={
        !isEmpty(coupon)
          ? {
              coupon: coupon.couponCodeText,
              toolTipMessage: 'Copied to clipboard'
            }
          : undefined
      }
      isBox={isBoxCheck}
      columnVariation={['100', null, columnDisplayOptions]}
      tagline={overline}
      headline={heading}
      mediaDisplay={mediaDisplay}
      mediaPosition={mediaPosition}
      imageAspectRatio={mediaDisplayRatio}
      imageDisplayMobile={imageDisplayMobile}
      backgroundColor={backgroundColor}
      isMobile={isMobile}
      {...props}
      textAlign={textAlign}
      minImageHeight="400px"
      imageUrl={
        media?.file?.url
          ? `${media.file.url}?w=544&h=400&fm=webp&q=80`
          : undefined
      }
      imageAlt={media?.description || FALLBACK_ALT_TEXT}
      callToAction={primaryCallToAction}
      headingTextStyle="heading1"
      taglineTextStyle="overline"
      bodyLineHeight="25px"
      headingMB="16px"
      body={
        paragraph && (
          <ContentfulRichText
            color="text.dark"
            textStyle="article"
            fontSize={3}
            body={paragraph}
          />
        )
      }
      actions={moduleButtons}
    />
  )
}

export default MerchModuleWrapper
