import React, { type FC, type ReactElement, type ReactNode } from 'react'

function locateChild(
  tag: FC<React.PropsWithChildren<unknown>>,
  children?: ReactNode
) {
  let child: React.ReactElement | null = null
  React.Children.forEach(children, c => {
    if (React.isValidElement(c) && c.type === tag) {
      child = child || c
    }
  })
  return (
    (child && ((child as ReactElement).props?.children as ReactElement)) || null
  )
}

export default function childByTagOr(
  tag: FC<React.PropsWithChildren<unknown>>,
  defValue?: ReactElement | (() => ReactElement),
  children?: ReactNode
) {
  const val = defValue || locateChild(tag, children)

  if (typeof val === 'function') {
    return val()
  }
  return val ?? null
}
