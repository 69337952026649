import analytics from '@/shared-utils/analytics'
import config from 'isomorphic-config'
import isAbortSignalTimeoutSupported from '@/shared-utils/is-abortSignal-supported'
import type { SourceId } from './EmailSignUp'

type AppCode = 'DESKTOP' | 'MOBILEWEB'

const DISCLAIMER_TEXT =
  'Exclusive access to coupons, special offers and promotions'

async function submitEmail(
  emailAddress: string,
  sourceId: SourceId,
  appCode: AppCode
) {
  const query = `mutation EmailSubscriptionByEmailAddress($emailAddress: String!, $subscriptionBaseParam: SubscriptionBaseParam!, $isEncrypted: Boolean) {
  emailSubscriptionByEmailAddress(emailAddress: $emailAddress, subscriptionBaseParam: $subscriptionBaseParam, isEncrypted: $isEncrypted) {
    message
    status
  }
}`

  const variables = {
    emailAddress,
    isEncrypted: false,
    subscriptionBaseParam: {
      appCode,
      disclaimerText: DISCLAIMER_TEXT,
      plfCode: 'PCLN',
      subscriptionStatus: true, // true = "subscribe"
      subscriptionSource: sourceId,
      placement: 'HOMEPAGE', // always HOMEPAGE. subscriptionSource is used for tracking different HP/LP
      subscriptionTypeId: 3 // 3 means just promo emails (no pricewatch and other alerts))
    }
  }

  try {
    const { url, timeout } = config.client['pcln-graph']
    const response = await fetch(url, {
      signal: isAbortSignalTimeoutSupported()
        ? AbortSignal.timeout(timeout)
        : undefined,
      method: 'POST',
      body: JSON.stringify({ query, variables }),
      headers: { 'content-type': 'application/json' }
    })

    const { status, statusText, type } = response

    if (!response.ok) {
      const logObject = {
        response: {
          status,
          payload: variables,
          statusText,
          type
        }
      }
      analytics.logError({
        message: 'Email registration response status is > 299',
        logObject
      })
      throw new Error(response.statusText)
    }

    const body = await response.json()

    if (body && body.errors) {
      const firstErrorMessage = body.errors?.[0]?.message
      const errorMessage = firstErrorMessage || 'Unknown error'
      const errorObjectAsString = JSON.stringify(body.errors)
      const message =
        'The emailSubscriptionByEmailAddress graph query response has returned errors.'
      analytics.logError({
        message,
        errorMessage,
        payload: variables,
        errorObjectAsString
      })

      throw new Error(errorMessage)
    }
  } catch (error) {
    const logObject = {
      emailAddress,
      payload: variables,
      error
    }
    if (error instanceof Error && error.name === 'TimeoutError') {
      analytics.logError({
        message: 'Timeout reached while invoking Email Registration API',
        logObject
      })
    } else {
      analytics.logError({
        message: 'Email registration failure - An unexpected error occurred',
        logObject
      })
      throw error
    }
  }
}

export default submitEmail
